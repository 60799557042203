import React, {useState, useEffect} from 'react';
import './Navbar.css';
import Fade from 'react-reveal/Fade';

import { GrInstagram } from 'react-icons/gr';

import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa/';


export const Navbar = () => {
    const [toggleMenu, setToggleMenu] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);


    const toggleNav = () => {
        setToggleMenu(!toggleMenu);
    }

    useEffect(() => {
        const changeWitdth = () => {
            setScreenWidth(window.innerWidth);
        }
        window.addEventListener('resize', changeWitdth);

        return () => {
            window.removeEventListener('resize', changeWitdth);
        }

    }, [])
    return ( 
        <Fade top cascade>
            <nav className='sidebar'>
                {(toggleMenu || screenWidth > 768) && ( 
                    <>
                    <ul className='list'>
                        <a href='/' className='navlinks'><li className="items">Inicio</li></a>
                        <a href="#about" className='navlinks'><li className="items">Nosotros</li></a>
                        <a href="#services" className='navlinks'><li className="items">Servicios</li></a>
                        <a href="#contact" className='navlinks'><li className="items">Contacto</li></a>
                        <div className='socialMedias'>  
                            <a href='https://www.instagram.com/estudiollebeili/' target='_blank' className='mediaLinks'><button className='iconoIg'><GrInstagram /></button></a>
                            <a href='https://www.facebook.com/estudiollebeiliCba/' target='_blank' className='mediaLinks'><button className='iconoFb'><FaFacebookF /></button></a>
                            <a href='https://www.linkedin.com/in/yamila-laura-llebeili-78376696/#' target='_blank' className='mediaLinks'><button className='iconoLin'><FaLinkedinIn /></button></a>
                        </div>
                    </ul>
                    </>
                )}
                <button onClick={toggleNav} className="btnResponsive">&#9776;</button>
            </nav>
        </Fade>
    )
}

export default Navbar;