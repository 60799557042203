import React, { useState } from 'react'
import yami from '../../Assets/YAMI.jpeg';
import joy from '../../Assets/JOY.jpg';
import { FaHandHoldingMedical, FaLightbulb, FaBalanceScale, FaHardHat } from 'react-icons/fa';
import { MdOutlineElderly, MdOutlineFamilyRestroom, MdWork, MdLocationPin } from 'react-icons/md';
import { GiArchiveRegister } from 'react-icons/gi';
import { BiWorld } from 'react-icons/bi';
import { HiOutlineMail } from 'react-icons/hi';
import { ImMobile, ImWhatsapp } from 'react-icons/im';
import { BsTelephone } from 'react-icons/bs';
import './About.css';
const About = () => {
    return (
        <div className='abtUsContainer' id='about'>
                <h1 className='titleInfo1'>ESTUDIO JURIDICO LLEBEILI</h1>
                <h5 style={{margin:'15px'}}>ASESORAMIENTO LEGAL - GESTION DE SOLUCIONES JURIDICAS</h5>
            <div className='us'>
                <p className='txtAbtUs'><h3>Bienvenido</h3>Somos un equipo de trabajo sólido, joven, altamente capacitado, actualizado, y con una <b>trayectoria de más de 12 años en el ejercicio de la profesión</b>, lo que nos permite afrontar y resolver de manera competente y con celeridad las distintas causas que nos son encomendadas.<br/><br/>Nos  distinguimos por la <b>eficacia y rapidez en la resolución de cada asunto</b>, mediante un trato <b>personalizado y comprometido</b>, ya que procuramos tener una comunicación fluida  y constante con nuestros clientes, porque  entendemos que es la forma de obtener los mejores resultados y de marcar la diferencia en el ejercicio de nuestra profesión.<br/><br/>
                Tenemos como premisa ejercer la abogacía con una visión <b>integral e innovadora</b> del derecho, haciéndolo de manera <b>responsable, proactiva, precisa, y práctica</b>, defendiendo los intereses de manera <b>comprometida y responsable</b>, lo que nos ha permitido crecer y sumar nuevos clientes en forma constante llegando a incorporar a nuestra cartera a <b>destacadas empresas</b> del ámbito local y nacional, logrando siempre los mejores resultados.<br/><br/>
                <b>Abogadas:</b><br/><br/>
                Yamila L. Llebeili (MP 1 - 36053; MF T 504 F 13)<br/><br/>
                Johana Zavaley MP (MP 1 - 41862; MF T 509 F 813)</p>
                <div className='empleados'>
                    <div className='empleado1'>
                        <h5 className='nombres'>AB. YAMILA L. LLEBEILI</h5>
                        <img src={yami} alt="empleado1" className='fotoEmpleados' />
                    </div>
                    <div className='empleado2'>
                        <h5 className='nombres'>AB. JOHANA ZAVALEY</h5>
                        <img src={joy} alt="empleado1" className='fotoEmpleados'/>
                    </div>
                </div>
            </div>
            <div className='services' id='services'>
                <h1 className='titleServices'>Servicios</h1>
                <div className='grid1'>
                    <div className='cardService'>
                        <FaHardHat className='iconoService'/>
                        <h1 className='titleService'>Laboral</h1>
                        <ul className='serviceList'>
                            <li>RECLAMOS ART</li>
                            <li>DESPIDOS</li>
                            <li>CALCULO DE INDEMNIZACIONES</li>
                            <li>ASESORAMIENTO, NEGOCIACION Y REALIZACION DE ACUERDOS JUDICIALES Y EXTRAJUDICIALES</li>
                            <li>LICENCIAS</li>
                            <li>DENUNCIAS MINISTERIO DE TRABAJO</li>
                            <li>DEMANDAS JUDICIALES</li>
                            <li>CONFLICTOS SINDICALES</li>
                        </ul>
                    </div>
                    <div className='cardService'>
                        <MdOutlineElderly className='iconoService'/>
                        <h1 className='titleService'>Previsional</h1>
                        <ul className='serviceList'>
                            <li>REVISION DE APORTES Y SITUACION PREVISIONAL</li>
                            <li>JUBILACIONES Y PENSIONES</li>
                            <li>JUBILACION SIN APORTES (MORATORIAS)</li>
                            <li>PENSION POR FALLECIMIENTO DE UN JUBILADO O TRABAJADOR EN ACTIVIDAD</li>
                            <li>PENSION UNIVERSAL (SIN APORTES)</li>
                            <li>CUESTIONES DE INVALIDEZ</li>
                            <li>PLANIFICACION PREVISIONAL</li>
                            <li>JUBILACION ANTICIPADA 2021</li>
                        </ul>
                    </div>
                    <div className='cardService'>
                        <MdWork className='iconoService'/>
                        <h1 className='titleService'>Civil</h1>
                        <ul className='serviceList'>
                            <li>CONFECCION Y ANALISIS DE CONTRATOS</li>
                            <li>DECLARATORIA DE HEREDEROS</li>
                            <li>JUICIO SUCESORIO</li>
                            <li>JUICIOS DE ESCRITURACION</li>
                            <li>COBRANZAS</li>
                            <li>JUICIOS EJECUTIVOS (COBRO DE CHEQUES, LETRAS O PAGARES)</li>
                            <li>DESALOJOS Y COBRO DE ALQUILERES</li>
                            <li>ACCIDENTES DE TRANSITO</li>
                        </ul>
                    </div>
                    <div className='cardService'>
                        <GiArchiveRegister className='iconoService'/>
                        <h1 className='titleService'>Comercial</h1>
                        <ul className='serviceList'>
                            <li>VERIFICACIONES DE CREDITO</li>
                            <li>PRONTO PAGO LABORAL</li>
                            <li>CONSTITUCION DE SOCIEDADES (SAS; SA;SRL Y OTROS TIPOS DE SOCIEDADES)</li>
                            <li>CONSTITUCION DE COOPERATIVAS. ASESORAMIENTO A GRUPOS PRE COOPERATIVOS Y DURANTE LA VIDA COOPERATIVA</li>
                        </ul>
                    </div>
                </div>
                <div className='grid1'>
                    <div className='cardService'>
                            <FaLightbulb className='iconoService'/>
                            <h1 className='titleService'>Propiedad Intelectual</h1>
                            <ul className='serviceList'>
                                <li>REGISTRO DE MARCAS</li>
                                <li>REGISTRO DE DERECHOS DE AUTOR</li>
                                <li>ASESORAMIENTO SOBRE MARCAS, PATENTES, MODELOS DE UTILIDAD Y DISEÑOS INDUSTRIALES</li>
                                <li>ASESORAMIENTO SOBRE DERECHOS DE AUTOR Y ASUNTOS ARTISTICOS</li>
                            </ul>
                    </div>
                    <div className='cardService'>
                            <FaHandHoldingMedical className='iconoService'/>
                            <h1 className='titleService'>Amparos de Salud</h1>
                            <ul className='serviceList'>
                                <li>RECLAMOS JUDICIALES Y EXTRAJUDICIALES ANTE OBRAS SOCIALES</li>
                            </ul>
                    </div>
                    <div className='cardService'>
                            <MdOutlineFamilyRestroom className='iconoService'/>
                            <h1 className='titleService'>Familia</h1>
                            <ul className='serviceList'>
                                <li>DIVORCIOS</li>
                                <li>SOLICITUD DE CUOTA ALIMENTARIA</li>
                                <li>AUMENTO / DISMINUCION DE CUOTA ALIMENTARIA</li>
                                <li>REGIMEN DE COMUNICACION</li>
                                <li>FILIACIONES</li>
                                <li>COMPENSACION ECONOMICA AL CONYUGE O CONCUBINO</li>
                            </ul>
                    </div>
                    <div className='cardService'>
                            <FaBalanceScale className='iconoService'/>
                            <h1 className='titleService'>Gestoría</h1>
                            <ul className='serviceList'>
                                <li>GESTION DE ACTAS DE NACIMIENTO, MATRIMONIA, DEFUNCION</li>
                                <li>GESTION DE INFORMES DE INMUEBLES Y AUTOMOTORES</li>
                                <li>DILIGENCIAMIENTO DE TRAMITES JUDICIALES Y EXTRAJUDICIALES ANTE ORGANISMOS PUBLICOS Y PRIVADOS</li>
                            </ul>
                    </div>
                </div>
            </div>
            <div className='location' id='contact'>
                <div className='contactInfo'>
                    <h1 className='titleInfo'>Contacto</h1>
                    <span className='info'><BiWorld className='iconInfo'/> Web: <a href="http://www.estudiollebeili.com/" className='linkInfo'>estudiollebeili.com</a></span>
                    <span className='info'><MdLocationPin className='iconInfo1'/> Dirección: <a href="https://g.page/estudio-llebeili?share" className='linkInfo'>Montevideo 480 piso 6º oficina "E", Córdoba.</a></span>
                    <span className='info'><HiOutlineMail className='iconInfo'/> Email: <a href="mailto:estudiollebeili@gmail.com" className='linkInfo'>estudiollebeili@gmail.com</a></span>
                    <span className='info'><BsTelephone className='iconInfo'/> Tel. Fijo: <a href="tel:5493514218777" className='linkInfo'>(0351) 4218777</a></span>
                    <span className='info'><ImMobile className='iconInfo'/> Celular: <a href='tel:5493513484587' className='linkInfo'>(0351) 153484587</a></span>
                    <span className='info'><ImWhatsapp className='iconInfo'/> WhatsApp: <a href="https://api.whatsapp.com/send?phone=5493513484587&text=Hola%20me%20gustar%C3%ADa%20hacer%20una%20consulta%20sobre%20sus%20servicios" className='linkInfo'>+549 351 3484587</a></span>
                    <h1 className='titleInfo'>Horario</h1>
                    <span className='info'><b className='horarios'>Lunes - Viernes</b> - 08:00 a 20:00hs</span>
                    <span className='info'><b className='horarios'>Sábados y Domingos</b> - Cerrado</span>
                </div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3404.784048203598!2d-64.19485753689507!3d-31.420075414870237!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9432a2872fd266e7%3A0x1186749e11eab2fe!2sEstudio%20Llebeili!5e0!3m2!1ses-419!2sar!4v1634597822843!5m2!1ses-419!2sar" allowfullscreen="" loading="lazy" className='map'></iframe>
            </div>
        </div>
    )
}

export default About
