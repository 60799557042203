import './App.css';
import Navbar from './Components/Navbar/Navbar';
import CarouselImages from './Components/CarouselImages/CarouselImages';
import Contact from './Components/Contact/Contact';
import About from './Components/About/About';
import Footer from './Components/Footer/Footer';
function App() {
  return (
    <div className="App">
      <Navbar />
      <CarouselImages />
      <Contact />
      <About />
      <Footer />
    </div>
  );
}

export default App;
