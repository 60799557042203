import React from 'react'
import logoGabi from '../../Assets/GABRIEL PEITEADO_v1.1_cream.png';
import './Footer.css';
const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer1'>
                Developed by
                <a href="https://www.linkedin.com/in/gabrielpeiteado/" target='_blank' style={{textDecoration:'none'}}><img src={logoGabi} alt="logo" className='logogabi' /></a>
            </div>
                <span className='footerIcon'>Estudio Llebeili</span>
            <div className='footer2'>
                <a href="/" className='footerLinks'>INICIO</a>
                <a href="#about" className='footerLinks'>NOSOTROS</a>
                <a href="#services" className='footerLinks'>SERVICIOS</a>
                <a href="#contact" className='footerLinks'>CONTACTO</a>
            </div>
        </div>
    )
}

export default Footer;
