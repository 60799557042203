import React, { useState } from 'react';
import './Contact.css';
import emailjs from 'emailjs-com'
import swal from 'sweetalert'
import Fade from 'react-reveal/Fade';
import { FaHandPointDown } from 'react-icons/fa';
const Contact = () => {
    const [info, setInfo] = useState({
        name: '',
        email:'',
        subject:'',
        message:''
    })
    const handleChange = (e) => {
        setInfo({
            ...info,
            [e.target.name]: e.target.value
        })
    }
    const sendEmail = (e) => {
        e.preventDefault();
        console.log(info)
        
        if (!info.name) {
            swal('¡Debe completar todos los campos!',{icon:"warning"})
        }
        if (!info.email) {
            swal('¡Debe completar todos los campos!',{icon:"warning"})
        }
        if (!info.subject) {
            swal('¡Debe completar todos los campos!',{icon:"warning"})
        }
        if (!info.message) {
            swal('¡Debe completar todos los campos!',{icon:"warning"})
        }
        else{
            emailjs.sendForm('service_7qtp0fn', 'template_1lexbmu', e.target, 'user_HPuiwRCeb36mTqrSENPOr')
              .then((result) => {
                  console.log(result)
                  swal('¡Gracias por contactarnos! Responderemos a la brevedad',{icon:"success"})
                  e.target.reset();
              }, (error) => {
                  alert(error.text);
              });
        }
      };
    

    return (
        <Fade top cascade>
        <div className='contactContainer' id='contactForm'>
            <h1 className='titleContact'>DEJANOS TU CONSULTA AQUI <FaHandPointDown/></h1>
            <form className='contactForm' id='form' onSubmit={sendEmail}>
                <label for="name" className='titleLabel'>Nombre Completo</label>
                <input type="text" name="name" className='inputStyle' onChange={handleChange} />
                <label for="email" className='titleLabel'>Email</label>
                <input type="email" name="email" className='inputStyle' onChange={handleChange} />
                <label for="subject" className='titleLabel'>Asunto</label>
                <input type="text" name="subject" className='inputStyle' onChange={handleChange} />
                <label for="message" className='titleLabel'>Mensaje</label>
                <textarea type="text" name="message" rows='7' className='txtAreaStyle' onChange={handleChange} />
                <button type="submit" className='btnSend'>Enviar</button>
            </form>
        </div>
        </Fade>
    )
}

export default Contact
