import React from 'react';
import banner from '../../Assets/logo FINAL.png';
import imageEstudio from '../../Assets/estudio_1.jpg';
import imageEstudio2 from '../../Assets/estudio_2.jpg';
import fotoAfuera1 from '../../Assets/fotoAfuera1.jpg';
import fotoInterior from '../../Assets/fotoInterior.jpg';
import Carousel from 'react-bootstrap/Carousel';
import './CarouselImages.css';
import Fade from 'react-reveal/Fade';
import { ImWhatsapp } from 'react-icons/im';
import { SiGmail } from 'react-icons/si';
import { BsTelephoneFill } from 'react-icons/bs';

const CarouselImages = () => {
    return (
        <>
        <Fade top cascade>
            <div className='divContactoBtns'>
                <h1 className='titleBtns'>CONTACTANOS</h1>
                <div className='btnsContact'>
                    <a className='contactMail' href='#contactForm'>Online <SiGmail className='iconContact'/></a>
                    <a className='contactWhatsapp' href='https://api.whatsapp.com/send?phone=5493513484587&text=Hola%20me%20gustar%C3%ADa%20hacer%20una%20consulta%20sobre%20sus%20servicios' target='_blank'>WhatsApp <ImWhatsapp className='iconContactWha'/></a>
                    <a className='contactTel' href='tel:03513484587'>Telefono <BsTelephoneFill className='iconContact'/></a>
                </div>
            </div>
            </Fade>
            <Fade top cascade>
            <div className='carousel'>
                <Carousel fade pause='hover' touch controls>
                    <Carousel.Item interval={3000}>
                        <img
                        className="d-block w-100"
                        src={banner}
                        alt="First slide"
                        />
                        <Carousel.Caption>

                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={3000}>
                        <img
                        className="d-block w-100"
                        src={imageEstudio}
                        alt="Second slide"
                        />
                        <Carousel.Caption>
                        <h3 className='txt1 showUpFx'>ASESORAMIENTO PERSONALIZADO</h3>
                        <p className='txt2 showUpFx'>EFICACIA Y RAPIDEZ EN LA RESOLUCION DE CADA ASUNTO</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={3000}>
                        <img
                        className="d-block w-100"
                        src={imageEstudio2}
                        alt="Third slide"
                        />
                        <Carousel.Caption>
                        <h3 className='txt1 showUpFx'>ESTUDIO <b className='apellido'>LLEBEILI</b></h3>
                        <p className='txt2 showUpFx'>ESTUDIO JURIDICO</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={3000}>
                        <img
                        className="d-block w-100"
                        src={fotoInterior}
                        alt="Fourth slide"
                        />
                        <Carousel.Caption>
                            <h3 className='txt1 showUpFx'>ESTUDIO <b className='apellido'>LLEBEILI</b></h3>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={3000}>
                        <img
                        className="d-block w-100"
                        src={fotoAfuera1}
                        alt="Fourth slide"
                        />
                        <Carousel.Caption>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            </Fade>
        </>
    )
}

export default CarouselImages;
